import React from 'react';
import { Link } from 'gatsby';
import Image from 'reusecore/src/elements/Image';
import DocumentationSection from '../../../../../containers/SaasModern/Documentation';
import example_week_columns from '../../../../../../../common/src/assets/image/2020/week_columns_with_task_list.png';
import template_options_week_column from '../../../../../../../common/src/assets/image/template_options_week_column_with_task_list.png';

const Documentation = () => {
  const content = (
    <div id="help">
      <p>
        The Week Column template generates a calendar showing 7 days on a single page. Each day will be a column, with all-day events at the top and timeslot rows below. Events that have start/end times will be placed within their associated timeslot
        cells. Tasks that are overdue or due during the week will be listed to the right of the day columns. This template is similar to the{' '}
        <Link to="/Documentation/Create-Calendar/Templates-and-Options/Week-Columns">
          Week Columns style
        </Link>
        , except it includes a task list.
      </p>
      <p>
        <Image alt="" src={example_week_columns} />
      </p>
      <h2>Template Settings</h2>
      <p>
        The Week Column with Task List template provides the following options when creating a
        calendar...
      </p>
      <Image alt="" src={template_options_week_column} style={{ width: 449 }} />
      <br />
      <table className="table">
        <tbody>
          <tr>
            <td>
              <strong>Start date</strong>
            </td>
            <td>
              The start date can be typed into the box or selected using the
              drop-down arrow. This date will be the left-most column in the
              generated calendar. If you want Sunday to be the first day of the
              week, make sure to pick Sunday as the starting date. Otherwise,
              pick Monday or any other day you'd like to have shown in the first
              column.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Number of weeks</strong>
            </td>
            <td>
              Multiple weeks can be generated. Each week will be placed on its
              own worksheet (Excel), page (Word), or slide (PowerPoint). You can
              select how many weeks to include in the output by clicking the
              drop-down arrow.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Timeslots</strong>
            </td>
            <td>
              A timeslot range and interval can be specified. The output will
              only show times between the specified start and end values. Each
              row will correspond to the specified timeslot length, such as 30
              minutes.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Max. columns/day</strong>
            </td>
            <td>
              Each day in the generated calendar will have up to the specified
              number of sub-columns. These columns are used in cases where there
              are multiple events or tasks that occur in the same timeslot. In
              other words, if you set the maximum number of columns to 6, the
              generated calendar will be able to display up to 6 events or tasks
              occurring in the same time slot.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Max. all-day rows</strong>
            </td>
            <td>
              All-day events and tasks will be shown in an all-day area of the
              generated calendar, which is usually above the timeslot rows. This
              value controls how many events or tasks can be shown in the
              all-day area. Each event or task will take up one row.
            </td>
          </tr>
          <tr>
            <td>
              <strong>All-day text behavior</strong>
            </td>
            <td>
              The text behavior of items shown in the all-day area. Options are{' '}
              <strong>Wrap text</strong>, <strong>Don't wrap text</strong>, and{' '}
              <strong>Shrink text to fit</strong>.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Item font size</strong>
            </td>
            <td>
              Override the default font size associated with each calendar data
              source.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Time format</strong>
            </td>
            <td>
              The time format. Supported formats are <strong>12-hour</strong>{' '}
              (8:00 PM), <strong>24-hour</strong> (20:00),{' '}
              <strong>Shortened</strong> (8pm), <strong>Military </strong>
              (2000), and <strong>Numeric </strong>(20.0). An example time is
              shown for each format in the drop-down list.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show timed multiday items in all-day area</strong>
            </td>
            <td>
              When checked, events or starts that have start and end times
              spanning multiple days will be shown in the all-day area instead
              of the timeslot columns.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show start time</strong>
            </td>
            <td>
              When checked, event or task start times will be shown. The
              ordering of times, title, and location values can be specified in
              the <Link to="/Documentation/Options">PrintableCal options</Link>.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show end time</strong>
            </td>
            <td>
              When checked, event or task end times will be shown.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show time on separate line</strong>
            </td>
            <td>
              When checked, event or task times will be shown on a separate
              line, above the title.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show location</strong>
            </td>
            <td>When checked, event or task locations will be shown.</td>
          </tr>
          <tr>
            <td>
              <strong>Show location on separate line</strong>
            </td>
            <td>
              When checked, event or task locations will be shown on a separate
              line, below the title and above the description.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show description</strong>
            </td>
            <td>
              When checked, descriptions will be included in event and task
              blocks (up to the available space).
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show blank line above description</strong>
            </td>
            <td>
              When checked, a blank line will be shown above the description, to
              help differentiate the description from the title and location
              values.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Use bold text for item titles</strong>
            </td>
            <td>
              When checked, item titles will be in bold text, to help
              differentiate from the location and description values.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show overdue tasks</strong>
            </td>
            <td>
              When checked, any tasks that will be overdue before the specified
              start date will be listed, even if they were scheduled to be due
              on an earlier date.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show completed tasks</strong>
            </td>
            <td>
              When checked, tasks that have already been completed will be listed.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show tasks in day columns</strong>
            </td>
            <td>
              When checked, tasks that are due during the week will also be shown in the day columns instead of only in the task list.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show tasks that have no due date</strong>
            </td>
            <td>
              When checked, tasks that don't have due dates will be listed.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/Create-Calendar/Templates-and-Options/Week-Columns-With-Task-List"
      commentsId="commentsplus_week_columns_with_task_list"
      title="Week Columns with Task List Template"
      description="The Week Columns with Task List template generates a printable calendar of 7 days, each in a column, with all-day events at the top and timeslot rows below. A task list is shown to the right of the day columns."
      keywords="week template, task list, print week, printable week, printable timeslots, Excel template, Excel calendar, Word calendar, 2020 calendar, week calendar"
      content={content}
    />
  );
};

export default Documentation;
